import * as React from "react"

const Section = ({ children, className, onHover, onClick }) => {

  return(
    <section className={className ? className : ""} onMouseEnter={onHover} onClick={onClick}>
      {children}
    </section>
  )
}

export default Section
